import React, { Component } from "react";
import "../Header/Header.css";
import Clients from "../Clients/Clients.jsx";
import ScrollToTop from "react-scroll-to-top";
import { FaAngleDoubleDown } from "react-icons/fa";  
import Connct from "../../../img/engineers.png";
import Why from "../../../img/Banner/a (1).jpg";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaGoogle } from "react-icons/fa";
export default class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataTeam: [],
      dataAbout: [],
    };
  }

  async componentDidMount() {
    const response = await fetch("https://ewest-egypt.com/api/products.php", {
      method: "GET",
    });

    const json = await response.json();
    let a = [];
    Object.keys(json).forEach(function (key) {
      a.push(json[key]);
    });
    this.setState({
      data: a,
    });

    const responseTeam = await fetch("https://ewest-egypt.com/api/team.php", {
      method: "GET",
    });

    const jsonTeam = await responseTeam.json();
    let aT = [];
    Object.keys(jsonTeam).forEach(function (key) {
      aT.push(jsonTeam[key]);
    });
    this.setState({
      dataTeam: aT,
    });

    const aboutResponse = await fetch("https://ewest-egypt.com/api/about.php", {
      method: "GET",
    });

    const aboutJson = await aboutResponse.json();

    let b = [];
    Object.keys(aboutJson).forEach(function (key) {
      b.push(aboutJson[key]);
    });
    this.setState({
      dataAbout: b,
    });
  }

  render() {
    return (
      <div className="Head">
        <ScrollToTop smooth top="1000" />

        {/* <!-- End Scroll  --> */}
        {/* ================================= */}
        {/* Start Slider */}
        <div className="slider" id="Home">
          <div className="coverslider">
            <div className="texthead">
              <h1 data-wow-duration="3s">
                watch out <br />
                the <span> modern INDUSTRIAL</span> <br />
                TESTING EQUIPMENT!
              </h1>
              <button className="btnvesco" data-wow-duration="2s">
                <a href="#Stocks"> view our work</a>
              </button>
              <div className="btndown">
                <a href="#About">
                  <FaAngleDoubleDown className="iconslider" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* ================================ */}
        {/* Awesome */}
        {/* <!-- Start Section Awesome --> */}
        <div className="Awesome" id="About">
          <h2 className="headsec" data-wow-duration="1s">
            about <span>us</span>
          </h2>
          <div className="container">
            {this.state.dataAbout.map((awesome, i) => {
              return (
                <div>
                  <div key={awesome.id} className="col-md-12 col-xs-6">
                    <div className="Awesome_img">
                      <div className="overlay_awesome">
                        <span>{awesome.desc}</span>
                      </div>
                      <img src={awesome.picture} alt="" />
                    </div>
                    <div className="Awesome_content">
                      <h2>{awesome.desc}</h2>
                      <p className="global_para">{awesome.text}</p>
                    </div>
                  </div>
                </div>
              );
            })} 
          </div>
        </div>
        {/* <!-- End Section Awesome --> */}
        {/* ================================================ */}
        <div className="project" id="Products">
          <div className="container">
            <h2 className="headsec">Products</h2>
            <div className="row mt-5">
              {this.state.data.map((product, i) => {
                return (
                  <>
                    <div key={i} className="col-md-4 col-xs-6">
                      <div className="product-info">
                        <div className="overlay_product">
                          <span>{product.name}</span>
                        </div>
                        <img src={product.picture} alt="" />
                      </div>
                      <h3>{product.details}</h3>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>

        <Clients />

        {/* <!-- Start Section Choose Us --> */}
        <div className="choose" id="Testimoninal">
          <div className="row">
            <div className="col-md-6 imgaaa">
              <img src={Why} className="w-100" alt="" />
            </div>
            <div className="col-md-6 chooseover">
              <div className="choose22">
                <h1>
                  Why <span>Choose Us</span>
                </h1>
                <p>
                  Ewest Egypt was is an exclusive distributor for Cole Parmer
                  Intrernaitnal USA to Egypt in addition to representing other
                  international companies in USA and Europe in Egypt deal with
                  variety of test equipments and still the main supplier of test
                  equipment to this Egyptian government agency since 1992. Our
                  Egyptian client in this project is General organization for
                  quality control for import and export, it's Egyptian
                  governmental agency in charge of testing the quality of the
                  products coming from abroad to Egypt, have a more than 90
                  engineers and technicians making maintenance services for all
                  test equipments
                </p>
                <hr />
                <p>
                  Ewest Egypt has three maintenance service centers:-
                  <br />
                  <strong>1st. one:</strong> Authorized form Ministry of
                  Industry and Trade at 6 El-Betch st. Small Industries Complex,
                  3rd floor, Alexandria.
                  <br />
                  <strong>2st. one:</strong> at 10 Al-Safa Tower, Ahmed Yassien
                  St., Al-Agmey, Alexandria.
                  <br />
                  <strong>3st. one:</strong> at 9 Mahmoud Ghoname St., 2nd
                  Floor, 6th Section, Nasr City, Cairo.
                </p>
                <button className="btnvesco" data-wow-duration="2s">
                  <a href="#Stocks"> view more</a>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Section Choose Us  --> */}
        {/* <!--    Start Section Team  --> */}
        <div className="team" id="team">
          <div className="container">
            <h2 className="headsec">team</h2>
            <div className="row mt-5">
              {this.state.dataTeam.map((team, i) => {
                return (
                  <>
                    <div className="col-md-4 col-xs-6">
                      <div className="product-info">
                        <div className="overlay_product">
                          <span>{team.name}</span>
                        </div>
                        <img src={team.img} alt="" />
                      </div>
                      <h3> {team.job} </h3>
                      <h6>Email : {team.email}</h6>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
        {/* <!--    End Section Team  --> */}
        {/* =============================================================== */}

        {/* ========================================= */}
        {/* ================================================
         */}
        <div className="container" id="connectUs">
          <div className="links_contact_all">
            <h2 className="headsec">
              Get <span>touch</span>
            </h2>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="img_contact">
                  <img src={Connct} alt="" />
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="links_contact">
                  <a href="http://www.facebook.com">
                    <FaFacebookF className="iconconnect" />
                  </a>
                  <a href="https://www.linkedin.com">
                    <FaLinkedinIn className="iconconnect" />
                  </a>
                  <a href="https://www.google.com">
                    <FaGoogle className="iconconnect" />
                  </a>
                  <a href="http://www.FaTwitter.com">
                    <FaTwitter className="iconconnect" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Section Get Connected --> */}
        {/* ====================================================== */}
        {/* <!-- Start Section Mapp --> */}
        <div className="container">
          <div className="map_contact">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=%20Cario,%20Egypt,9%20Mahmoud%20Ghoname%20St.,6th.Section,Nasr%20City&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0"
                  scrolling="no"
                  title="myFrame"
                  marginHeight="0"
                  marginWidth="0"
                ></iframe>
                <a href="https://putlocker-is.org">putlocker</a>
                <br />
                <style></style>
                <a href="https://www.embedgooglemap.net">embed map in gmail</a>
                <style></style>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Section Mapp --> */}
        {/* ================================================ */}
        {/* <!-- Start Section Jumbo  --> */}
        <div className="jumbooo">
          <div className="jumbotron jumbotron-fluid">
            <div className="container">
              <div
                className="row wow fadeInLeft"
                data-wow-delay=".2s"
                data-wow-offset="200"
              >
                <div className="col-md-7 col-sm-12  offset-md-1">
                  <h3>You think we're cool? let's work together</h3>
                </div>
                <div className="col-md-4 col-sm-12 ">
                  <button type="button" className="btn hvr-grow-shadow">
                    <a href="#team">JOIN US</a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Section Jumbo  --> */}
        {/* ============================== */}
      </div>
    );
  }
}
