import React, { useState } from "react";
import "../Home/Header/Header.css"; 
import MyImagee from "../Home/iconweb.png";  
function Navbar() { 
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const [nav, setColor] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 700) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };
  return (
    // <nav  className="nav fixed-top">
    <nav className={nav ? "nav activee fixed-top" : "nav fixed-top"}>
      <a href="#Home" className="nav__brand">
        <img src={MyImagee} alt=""/>
        <span> Ewest Egypt</span>
      </a>
      <ul className={active}>
        <li className="nav__item" >
          <a href="#Home" className="nav__link"> home</a>
        </li>
        { 
          <li className="nav__item">
            <a href="#About" className="nav__link"> About</a>
          </li>
         }
       
 <li className="nav__item">
 <a href="#Products" className="nav__link"> Products</a>
  
        </li>

          <li className="nav__item">
          <a href="#Testimoninal" className="nav__link"> Testimoninal</a>
          
            
          
        </li>
         
            <li className="nav__item">
            <a href="#team" className="nav__link"> Team</a>
          

        </li>
           <li className="nav__item">
           <a href="#Stocks" className="nav__link"> Stocks</a>
        </li>
         

    
   <li className="nav__item">
          
    
   <a href="#cllients" className="nav__link"> Clients</a>
           
        </li>
        
           
        <li className="nav__item">
        <a href="#connectUs" className="nav__link"> connectUs</a>
        </li>
         

        <div className="form-group">
          <input
            type="search"
            id="cont4"
            placeholder="search"
          />
        </div>
      </ul>
      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
}

export default Navbar;
