import React,{useState,useEffect} from 'react';
import "../Clients/Clients.css";
export default function Clients() {
  const [data, setData] = useState([]);

  useEffect(() => {
    return () => {
      const fetchData = async () => {
        const data = await fetch('https://ewest-egypt.com/api/sponsers.php');

        const json = await data.json(); 
        let a =[];
        Object.keys(json).forEach(function (key) {
          a.push(json[key]);
        });
        setData(a);
      }
      fetchData();
    };
  })
  return (
    <div>
      {/* =================================== */}
      {/* =====================================    */}
      <div className="slider_clients" id="cllients">
        <div class="overlayall justfiy-content-center">
          <h1 class="display-3">
            ewest egypt <span>Clients</span>
          </h1>
        </div>
      </div>
      {/* =================================================== */}
      <div class="clients">
        <div className="container">
          <div className="row mt-5">
            {data.map((Clients, i) => {
              return (
                <>
                  <div  key={i} className="col-md-3 col-xs-6">
                    <div className="product-info">
                      <img src={Clients.img} alt="" />
                    </div>
                    <h3> {Clients.name}</h3>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      {/* =============================================== */}
    </div>
  );
}
