import React from "react";
import "./App.css";
import Header from "../src/components/Home/Header/Header";
import Footer from "../src/components/Home/Footer/Footer";
import Navbar from "../src/components/Home/Navbar";
import "bootstrap/dist/css/bootstrap.min.css"; 
function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <Footer />
    </div>
  );
}

export default App;
