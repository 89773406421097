import React, { Component } from 'react'
import "../Footer/Footer.css";
import { FaFacebookF,FaTelegramPlane, FaPhone, FaHome , FaGooglePlusG,FaLinkedinIn,FaInstagram} from 'react-icons/fa'; 
import {Link} from 'react-scroll'; 
export default class Footer extends Component {
  render() {
    return (
 <div className='Footer'>

{/* <!-- Start Section contact --> */}
    <div class="block" id="contact">
        <div class="cont">
     <div class="container">
            <h2 class="headsec  wow animated bounceInDown" data-wow-duration="1s">contact <span>us</span></h2>
            <div class="row">
                <div class="col-md-6 col-sm-12 wow animated bounceInLeft" data-wow-duration="1s"
                data-wow-delay=".3s">
                <div class="one-cont">
                    <div class="one-cont-1">
                      <span> 
                        Ewest Egypt
                      </span>
                    <p class="par">the main supplier of <strong>test equipment</strong> to this Egyptian government agency since 1992,
                    it's Egyptian governmental agency in charge of testing the quality of the products coming from abroad to Egypt.</p>
                    </div>
                    
                    <div class="one-cont-2">
                    <div class="oc2">
                    <FaHome className='iconfoot' />
                            <span class="par"> cario, egypt,9 Mahmoud Ghoname st.,6th.Section,Nasr City
                        </span>
                        </div>    
                        
                      <div class="oc2">
                      <FaPhone className='iconfoot' />
                            <span class="par"> Phone:
                           
                             <Link to="home" className='link' spy={true} smooth={true} offset={-50} duration={800}>
                             www.ewest-egypt.com
          </Link>
                        </span>
                        </div>  
                        
                        
                         <div class="oc2">
                         <FaTelegramPlane className='iconfoot' />
                            <span class="par">Email: sales@ewest-egypt.com
                        </span>
                        </div>  
                   
                    </div>
                    
                     <div class="one-cont-3">
                         <span>
                          <a href="http://www.fb.com">
                          <FaFacebookF className='iconfoot'/>
                          </a>
                          <a href="http://www.google.com">
                          <FaGooglePlusG className='iconfoot' />
                          </a>
                          <a href="http://www.linkedin.com">
                          <FaLinkedinIn className='iconfoot' />
                          </a>
                    
                      <a href="http://www.instagram.com">
                      <FaInstagram className='iconfoot' />
                          </a>
                     
                     
                         </span>
                    </div>
                   
                    </div>
                </div>
<div class="col-md-6 col-sm-12 wow animated bounceInRight" data-wow-duration="1s"
                data-wow-delay=".6s">
                 <div class="two-cont">
                    <form>
                        <div class="form-group">
    <input type="text" class="form-control" id="cont4" placeholder="Your Name"/>
  </div>
  <div class="form-group">
    <input type="email" class="form-control" id="cont" aria-describedby="emailHelp" placeholder="Your Email"/>
    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
  </div>
    <div class="form-group">
    <textarea class="form-control" placeholder="Message" id="cont2" rows="6"></textarea>
  </div>
  <button type="submit" class="btn rounded-0 btn-primary">Submit</button>
</form>
                    </div>
                </div> 
         </div>
        </div>
        </div>
    </div>
        {/* <!-- End Section contact --> */}
        
        
        {/* <!-- Start Section Footer --> */}
        <div class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-sm-12 wow animated bounceInLeft" data-wow-duration="1s"
                >
                        <div class="first-footer">
                           <p class="par"> copyrights &#64; 2022 all rights reversed by <strong>
                           
                           <a href="#Home" className='west'>
                        EWEST
                        </a>
    
                            </strong>.inc.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 d-none d-sm-block">
                        <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item west active" data-wow-duration="1s" data-wow-delay=".2s" aria-current="page">Home</li>
    <li class="breadcrumb-item " data-wow-duration="1s" data-wow-delay=".4s">

    <a href="#About" className="west"> About</a>
      </li>
     <li class="breadcrumb-item wow animated bounceInLeft" >
     <a href="#Testimoninal" className="west"> Testimoninal</a>
      </li>
      <li class="breadcrumb-item wow animated bounceInLeft" >
      <a href="#Products" className="west"> Products</a>
      </li>
 <li class="breadcrumb-item wow animated bounceInLeft">
 <a href="#connectUs" className="west"> connect</a>
 </li>
  <li class="breadcrumb-item wow animated bounceInLeft" >
  <a href="#team" className="west"> team</a>
  </li>
  <li class="breadcrumb-item wow animated bounceInLeft" >
  <a href="#Stocks" className="west"> Stocks</a>
  </li>
  <li class="breadcrumb-item wow animated bounceInLeft" >
  <a href="#cllients" className="west"> Clients</a>
  </li>
  </ol>
</nav>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Section Footer --> */}


 </div>




    )
  }
}
